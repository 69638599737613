import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import AuthLogin, {
  realmAcceptCode,
  realmCheckSSO,
  realmLogout,
  instanceInit,
} from './routes/AuthLogin';

import { AuthCredential } from 'swuif';
import {
  authStorage,
  keycloak,
  ROLE_TYPE,
  ROLE_TYPE_DISPLAY,
  GSE_ROLE_TYPE,
  TIPS_ROLE_TYPE,
  ROUTINE_ROLE_TYPE,
} from '@/config';
import {
  ROLE_TYPE as MALL_ROLE_TYPE,
  VDA_ROLE_TYPE,
  ASSETS_ROLE_TYPE,
  EXPLORE_ROLE_TYPE,
} from '@/config/constants_mall';
import { activity } from './components/ActivityMonitoring';
import { parseGSEtoken } from './clients/gse/utils';
import { extractEmail } from './config/utils';
// Apollo GraphQL mall
// import { ApolloClient } from 'apollo-client';
// import { InMemoryCache } from 'apollo-cache-inmemory';
// import { HttpLink } from 'apollo-link-http';
// import { ApolloProvider } from '@apollo/react-hooks';

//Apollo
// const cache = new InMemoryCache();
// const link = new HttpLink({
//   uri: 'https://brochure-na03s-useast2.samsungiots.com/graphiql',
//   headers: {
//     Authorization: `f9c171f2-2838-4082-8079-0c9e8ae8d66d`,
//   },
// });

// const client = new ApolloClient({
//   cache,
//   link,
// });

const STCMS_URL: string = process.env.REACT_APP_STCMS_URL || '';

const parseRole = (
  result: {},
  roleSet: Set<string>,
  options: string[], // sorted by descending authority
  realmID: string,
) => {
  for (let i = 0; i < options.length; i++) {
    if (roleSet.has(options[i])) {
      result[realmID] = options[i];
      break; // can only have 1 role per realms
    }
  }
};

const getRoleSet = (
  userInfo: Keycloak.KeycloakTokenParsed,
  realmID?: string,
) => {
  let roles = null;
  if (realmID && realmID !== keycloak.realm.RECOM) {
    const id = keycloak.realms[realmID].config.clientId;
    const tmp = userInfo.resource_access;
    if (tmp && tmp[id]) roles = tmp[id].roles;
  } else {
    roles = userInfo.realm_access && userInfo.realm_access.roles;
  }
  return roles ? new Set(roles.map(s => s.toUpperCase())) : null;
};

const getRoleInfo = (
  userRole: {},
  userInfo: Keycloak.KeycloakTokenParsed,
  realmID: string,
) => {
  const roleSet = getRoleSet(userInfo, realmID);

  // keycloak.debugRealmAlert(131, ['getRoleInfo', realmID, roleSet, userInfo]);

  if (!roleSet) return;
  parseRole(
    userRole,
    roleSet,
    [GSE_ROLE_TYPE.GSE_ADMIN, GSE_ROLE_TYPE.GSE_VENDOR],
    keycloak.realm.GSE,
  );
  parseRole(
    userRole,
    roleSet,
    [
      ROLE_TYPE.RECOMMENDATION_ADMIN,
      ROLE_TYPE.RECOMMENDATION_EDITOR,
      ROLE_TYPE.RECOMMENDATION_GENERAL,
    ],
    keycloak.realm.RECOM,
  );
  parseRole(
    userRole,
    roleSet,
    [
      // MALL_ROLE_TYPE.MALL_ADMIN,
      MALL_ROLE_TYPE.MALL_PORTAL_MANAGER,
      MALL_ROLE_TYPE.MALL_ACCOUNT_MANAGER,
      MALL_ROLE_TYPE.MALL_MARKETER,
      MALL_ROLE_TYPE.MALL_READ_ONLY,
    ],
    keycloak.realm.MALL,
  );
  parseRole(
    userRole,
    roleSet,
    [
      VDA_ROLE_TYPE.VDA_ADMIN,
      VDA_ROLE_TYPE.VDA_MEMBERS,
      VDA_ROLE_TYPE.VDA_READ_ONLY,
    ],
    keycloak.realm.VDA,
  );
  parseRole(
    userRole,
    roleSet,
    [
      TIPS_ROLE_TYPE.TIPS_MANAGER,
      TIPS_ROLE_TYPE.TIPS_USER,
      TIPS_ROLE_TYPE.TIPS_READ_ONLY,
    ],
    keycloak.realm.TIPS,
  );
  parseRole(
    userRole,
    roleSet,
    [
      ASSETS_ROLE_TYPE.ASSETS_MANAGER,
      ASSETS_ROLE_TYPE.ASSETS_USER,
      ASSETS_ROLE_TYPE.ASSETS_READ_ONLY,
    ],
    keycloak.realm.ASSETS,
  );
  parseRole(
    userRole,
    roleSet,
    [
      ROUTINE_ROLE_TYPE.ROUTINE_ADMIN,
      ROUTINE_ROLE_TYPE.ROUTINE_EDITOR,
      ROUTINE_ROLE_TYPE.ROUTINE_GENERAL,
    ],
    keycloak.realm.ROUTINE,
  );
  parseRole(
    userRole,
    roleSet,
    [
      EXPLORE_ROLE_TYPE.EXPLORE_MANAGER,
      EXPLORE_ROLE_TYPE.EXPLORE_USER,
      EXPLORE_ROLE_TYPE.EXPLORE_READ_ONLY,
    ],
    keycloak.realm.EXPLORE,
  );
  parseRole(userRole, roleSet, ['SUPERADMIN'], 'ADMIN_CONSOLE');
};

const loginRealms = async (userRole: {}) => {
  const tmp = keycloak.realm;
  const magic = [tmp.GSE, tmp.MALL, tmp.RECOM]; // MAGIC!, DON'T CHANGE :p
  console.log('###userRole', userRole);
  const realms = magic.filter(id => userRole[id]);
  if (realms.indexOf('RECOM') === -1 && userRole['ROUTINE']) {
    realms.push('RECOM');
  }
  if (realms.indexOf('GSE') === -1 && userRole['TIPS']) {
    realms.push('GSE');
  }
  for (const realmID of realms) {
    const [initConfig] = instanceInit(realmID);
    const prioritizeMissingToken = !(initConfig && initConfig.token);
    if (prioritizeMissingToken) {
      await realmCheckSSO(realmID, true);
    }
  }
  const countParsed = (await Promise.all(
    realms.map(async realmID => {
      const kc = await realmCheckSSO(realmID, true);
      const userInfo = kc && kc.tokenParsed;

      if (realmID === tmp.HOME) {
        // already parsed
      } else if (!userInfo) {
        // delete userRole[realmID]; // ???
      } else {
        if (realmID !== tmp.RECOM) {
          delete userRole[realmID]; // will replace with from original realms
        } else {
          if (tmp['RECOM'] === ROLE_TYPE.RECOMMENDATION_GENERAL)
            userRole[realmID] = ROLE_TYPE.RECOMMENDATION_GENERAL; // fix PRESCRIBER_SERVICE
        }
        getRoleInfo(userRole, userInfo, realmID);
        if (realmID === tmp.GSE) parseGSEtoken(userRole, userInfo);
      }
      return userInfo;
    }),
  )).filter(b => b).length;
  const allParsed = countParsed === realms.length;
  if (!allParsed) loginRealms(userRole); // rare case somehow revoked
};

const updateUserInfo = async (userInfo: Keycloak.KeycloakTokenParsed) => {
  const userRole = {};
  getRoleInfo(userRole, userInfo, keycloak.realm.HOME);
  await loginRealms(userRole);

  // console.log('[keycloak.init] userRole=', userRole);
  const userCredential: AuthCredential = {
    key: userInfo['preferred_username'] || '',
    user: {
      userId: userInfo['email'] || '',
      fullName: extractEmail(userInfo['preferred_username'] || ''),
      role: ROLE_TYPE_DISPLAY[userRole[keycloak.realm.MALL]],
      countries: userRole[keycloak.realm.GSE + '-countries'],
      roles: userRole,
      mallReadOnly: userInfo['mall-state'] === 'read-only',
      gseReadOnly: userInfo['gse-state'] === 'read-only',
    },
  };
  // console.log('[keycloak.init] userCredential=', userCredential);
  await authStorage.store(userCredential, true);
  activity.setUser(
    userCredential.user,
    window.screen.availHeight,
    window.screen.availWidth,
  );
  return Object.keys(userRole).length;
};

const parseParam = (url: string) => {
  let urls = url.split('?');
  let result = {};
  if (urls.length === 2) {
    urls = urls[1].split('&');
    urls.forEach(value => {
      const val = value.split('=');
      const key = val[0];
      let v: any = val[1];
      if (v === 'false') v = false as boolean;
      else if (v === 'true') v = true as boolean;
      result[key] = v;
    });
  }
  return result;
};

if (
  process.env.REACT_APP_SECRET_SERVER_URL !==
    'https://keycloak-api-d.portal.samsungiots.com' &&
  process.env.REACT_APP_SECRET_SERVER_URL !== 'http://localhost:5000'
) {
  console.log = function() {
    // eslint-disable-next-line
  }.bind(console.log);
  console.info = function() {
    // eslint-disable-next-line
  }.bind(console.info);
  console.warn = function() {
    // eslint-disable-next-line
  }.bind(console.warn);
}

(async () => {
  if (process.env.REACT_APP_IS_CHINA) {
    await realmAcceptCode();
    if (keycloak.debug > 1) {
      ReactDOM.render(<AuthLogin />, document.getElementById('root'));
      return;
    }
    const params = parseParam(window.location.href);
    const skipAsk: boolean = params['skipAsk'] || false;
    let isStatus: string = 'login';
    const kc = await realmCheckSSO(keycloak.realm.HOME, false);
    if (kc && kc.tokenParsed) {
      const count = await updateUserInfo(kc.tokenParsed);
      if (count && !skipAsk) isStatus = 'main';
    } else {
      await realmLogout(false); // from all realms
      activity.setUser();
    }
    ReactDOM.render(
      <App registered={isStatus}></App>,
      document.getElementById('root'),
    );
  } else {
    window.location.replace(STCMS_URL);
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
