export const APP_NAME = 'Static Recommendation';
export const COMPANY_NAME = 'SAMSUNG';
export const UPDATE_TOKEN_BEFORE = 10;
export const UNAUTHORIZED_URL = '/401';
export const NOACCESS_URL = '/403';
export const NOTFOUND_URL = '/404';
export const INTERNALSERVER_URL = '/500';

export const GSE_KEYCLOAK_IDP = process.env.REACT_APP_GSE_KEYCLOAK_IDP || '';
export const GSE_KEYCLOAK_CLIENTID =
  process.env.REACT_APP_GSE_KEYCLOAK_CLIENTID || '';
export const GSE_KEYCLOAK_REALM =
  process.env.REACT_APP_GSE_KEYCLOAK_REALM || '';
export const GSE_KEYCLOAK_HOST = process.env.REACT_APP_GSE_KEYCLOAK || '';

export const MALL_KEYCLOAK_IDP = process.env.REACT_APP_MALL_KEYCLOAK_IDP || '';
export const MALL_KEYCLOAK_CLIENTID =
  process.env.REACT_APP_MALL_KEYCLOAK_CLIENTID || '';
export const MALL_KEYCLOAK_REALM =
  process.env.REACT_APP_MALL_KEYCLOAK_REALM || '';
export const MALL_KEYCLOAK_HOST = process.env.REACT_APP_MALL_KEYCLOAK || '';

export const RECOM_KEYCLOAK_CLIENTID =
  process.env.REACT_APP_RECOM_KEYCLOAK_CLIENTID || '';
export const RECOM_KEYCLOAK_REALM =
  process.env.REACT_APP_RECOM_KEYCLOAK_REALM || '';
export const RECOM_KEYCLOAK_HOST = process.env.REACT_APP_RECOM_KEYCLOAK || '';
export const RECOM_EXP_CONSOLE_HOST =
  process.env.REACT_APP_RECOM_EXP_CONSOLE_HOST;
export const ACTION_TYPE = {
  DELETE: 'delete',
  SUBMIT: 'submit',
  APPROVE: 'approve',
  REJECT: 'reject',
  ACTIVATE: 'activate',
  EDIT: 'edit',
  ARCHIVE: 'archive',
  PAUSE: 'pause',
  CREATE: 'create',
  REPLICATE: 'replicate',
  DISCARD: 'discard',
  COMPLETE: 'complete',
  UPLOAD: 'upload',
  UPDATE: 'update',
  DISCARD_ALL: 'discard all',
  ENABLE: 'enable',
  DISABLE: 'disable',
  CANCEL: 'cancel',
  SYNC: 'sync',
  WITHDRAWN: 'withdraw',
  PROMOTE: 'promote',
};
export const ACTION_TYPE_MSG = {
  [ACTION_TYPE.UPDATE]: 'updated',
  [ACTION_TYPE.SUBMIT]: 'submited',
  [ACTION_TYPE.APPROVE]: 'approved',
  [ACTION_TYPE.REJECT]: 'rejected',
  [ACTION_TYPE.DELETE]: 'deleted',
  [ACTION_TYPE.ACTIVATE]: 'activated',
  [ACTION_TYPE.EDIT]: 'edited',
  [ACTION_TYPE.ARCHIVE]: 'archived',
  [ACTION_TYPE.PAUSE]: 'paused',
  [ACTION_TYPE.CREATE]: 'created',
  [ACTION_TYPE.REPLICATE]: 'replicated',
  [ACTION_TYPE.DISCARD]: 'discarded',
  [ACTION_TYPE.UPLOAD]: 'uploaded',
  [ACTION_TYPE.COMPLETE]: 'completed',
  [ACTION_TYPE.DISCARD_ALL]: 'discarded',
  [ACTION_TYPE.ENABLE]: 'enabled',
  [ACTION_TYPE.DISABLE]: 'disabled',
  [ACTION_TYPE.SYNC]: 'sync',
  [ACTION_TYPE.WITHDRAWN]: 'withdraw',
  [ACTION_TYPE.PROMOTE]: 'promoted',
};
export const STATUS_TYPE = {
  ACTIVE: 'active',
  ACTIVE_RUNNING: 'active_running',
  ACTIVE_NOT_STARTED: 'active_not_started',
  DRAFT: 'draft',
  PENDING: 'pending',
  PENDING_APPROVAL: 'pending_approval',
  COMPLETED: 'completed',
  PAUSED: 'paused',
  APPROVED: 'approved',
  WITHDRAW: 'withdraw',
};
export const PRESCRIBER_STATUS_TYPE = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  PENDING: 'PENDING',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  COMPLETED: 'COMPLETED',
  PAUSED: 'PAUSED',
  APPROVED: 'APPROVED',
  WITHDRAW: 'WITHDRAW',
};
export const STCMS_ENV = {
  DEV: 'Development',
  STG: 'Staging',
  'STG CN': 'Staging China',
  ACT: 'Acceptance',
  PROD: 'Production',
  'PROD CN': 'Production China',
};
export const PRESCRIBER_NOTIFICATION_DELIVER_STATUS_TYPE = {
  PUSHING: 'PUSHING',
  STOP: 'STOP',
};
export const PRESCRIBER_SCENARIO_TYPE = {
  NO_DEVICE: 'NO_DEVICE',
  TIME_DEVICE: 'TIME_DEVICE',
  DEVICE_NOTIFY: 'DEVICE_NOTIFY',
  DEVICE_DEVICE: 'DEVICE_DEVICE',
  TIME_DEVICE_DEVICE: 'TIME_DEVICE_DEVICE',
  MEMBER_DEVICE: 'MEMBER_DEVICE',
};
export const PRESCRIBER_ACTION_TRANSITION = {
  [ACTION_TYPE.ACTIVATE]: PRESCRIBER_STATUS_TYPE.ACTIVE,
  [ACTION_TYPE.SUBMIT]: PRESCRIBER_STATUS_TYPE.PENDING_APPROVAL,
  [ACTION_TYPE.PAUSE]: PRESCRIBER_STATUS_TYPE.PAUSED,
  [ACTION_TYPE.COMPLETE]: PRESCRIBER_STATUS_TYPE.COMPLETED,
  [ACTION_TYPE.APPROVE]: PRESCRIBER_STATUS_TYPE.APPROVED,
  [ACTION_TYPE.WITHDRAWN]: PRESCRIBER_STATUS_TYPE.WITHDRAW,
};
export const PRIORITY_OPT = [
  { label: '1 (Highest)', value: 1 },
  { label: '2', value: 2 },
  { label: '3 (Default)', value: 3 },
  { label: '4', value: 4 },
  { label: '5 (Lowest)', value: 5 },
];
export const DATE_STR_FORMAT = 'DD/MM/YYYY HH:mm';
export const CAMPAIGN_JSON_FILENAME = 'campaigns.json';
export const NOTIFICATION_JSON_FILENAME = 'notifications.json';
export const statusColorBackground = {
  DRAFT: '#F0F0F0',
  PENDING_APPROVAL: '#FFF5E3',
  PENDING: '#FFF5E3',
  ACTIVE: '#E2F8F3',
  APPROVED: '#FCE6D9',
  PAUSED: '#FFE3E3',
  COMPLETED: '#DEF7FD',
  WITHDRAW: '#76996A',
};
export const statusColor = {
  DRAFT: '#8D8D8D',
  PENDING_APPROVAL: '#F7A81F',
  PENDING: '#F7A81F',
  ACTIVE: '#139C80',
  APPROVED: '#EF7E3C',
  PAUSED: '#EC7D7D',
  COMPLETED: '#44B1D1',
  WITHDRAW: '#FFFFFF',
};

export const MENU_STEPS = {
  TO_STRING: ['campaignForm', 'recommendationForm'], // 'ABTestingForm'],
  TO_INT: {
    campaignForm: 0,
    recommendationForm: 1,
    // ABTestingForm: 2,
  },
};

export const MENU_STEPS_NEW = {
  TO_STRING: ['categoryForm', 'presetForm'],
  TO_INT: {
    categoryForm: 0,
    presetForm: 1,
  },
};

export const SECRET_SERVER_URL = process.env.REACT_APP_SECRET_SERVER_URL || '';
// export const USE_SECRET_SERVER = SECRET_SERVER_URL.length > 0;
export const DID_PRIVATE_KEY = process.env.REACT_APP_DID_PRIVATE_KEY || '';

export const DID_SETTING_BRANCH = process.env.REACT_APP_DID_BRANCH_NAME || '';

export const DID_SETTING_REPO = process.env.REACT_APP_DID_REPO_NAME || '';

export const VDA_PRIVATE_KEY = process.env.REACT_APP_VDA_PRIVATE_KEY || '';

export const domainToKey = {
  'samsung.com': 's',
  'partner.samsung.com': 'p',
  'smartthings.com': 'st',
  'sea.samsung.com': 'sea',
  'ea.corp.samsungelectronics.net': 'eac',
  'miracom.co.kr': 'mira',
  'stage.partner.samsung.com': 'stgp',
  'partner.sec.co.kr': 'psec',
  'gra-ph.com': 'g',
  'secui.com': 'sc',
};

export const keyToDomain = {
  s: 'samsung.com',
  p: 'partner.samsung.com',
  st: 'smartthings.com',
  sea: 'sea.samsung.com',
  eac: 'ea.corp.samsungelectronics.net',
  mira: 'miracom.co.kr',
  stgp: 'stage.partner.samsung.com',
  psec: 'partner.sec.co.kr',
  g: 'gra-ph.com',
  sc: 'secui.com',
};

export const sessionStorageService = {
  PLUGIN: {
    TIPS: 'plugin-tips',
    SUPPORT: 'plugin-support',
    DID_REGIST: 'plugin-did',
  },
  ASSETS: {
    ICON: 'assets-icon',
    IMAGE: 'assets-image',
    OCF: 'assets-ocf',
  },
  EXPLORE: {
    POST: 'explore-post',
    TAG: 'explore-tag',
  },
  MALL: {
    PRODUCT: 'mall-product',
    LIFE: 'mall-life',
    CATEGORY: 'mall-category',
    BRAND: 'mall-brand',
    SUPERCATEGORY: 'mall-supercategory',
  },
  GSE: {
    PAGE: 'gse-page',
    HERO_BANNER: 'gse-herobanner',
    NOTICE: 'gse-notice',
  },
  RECOM: {
    CAMPAIGN: 'recom-campaign',
    NOTIFICATION: 'recom-notification',
    PRESET_ROUTINE: 'recom-presetroutine',
    ROUTINE_TEMPLATE: 'recom-routinetemplate',
  },
  VDA: {
    DEVICE: 'vda-device',
    RESOURCE: 'vda-resource',
  },
  MISC: {
    DID_SETTING: 'misc-did',
    USER_MANAGEMENT: 'misc-user',
  },
};

export const CONTENT_TYPE = [
  { label: 'Regular', value: false },
  { label: 'Dynamic', value: true },
];
